export const Environments = {
  stg: {
    configUrl: 'https://api.vouchersstg.vodacom.co.za/stg/v1/ui/config',
    analyticsUrl: 'https://tags.tiqcdn.com/utag/vodafone/za-vodapayapp/prod',
    sentryConfig: {
      dsn: 'https://9d2a0b934107690b891d9daed977779c@o185340.ingest.sentry.io/4505917819650048',
      authToken: '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',
      tracesSampleRate: 1,
      project: 'vod-mini-app-vouchers-redesign',
    },
  },
};

export const CurrentEnvironment = 'stg';
